import React from "react";
import Layout from "@components/Layout";
import SEO from "@components/SEO";
import { Container, Row, Col } from "react-bootstrap";

export default function NotFound() {
  return (
    <Layout>
      <div className="vh-100">
        <SEO title="Oops! Halaman yang kamu cari tidak ditemukan." />
        <Container fluid className="h-100 py-5">
          <Row className="h-100 justify-content-center align-items-center">
            <Col className="mb-4">
              <Row className="justify-content-center align-items-end text-center">
                <Col md={12}>
                  <h2>404 : Halaman yang kamu cari tidak ditemukan.</h2>
                  <span
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={() => window.history.back()}
                  >
                    Kembali
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}
